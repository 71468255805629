import moment from 'moment' // parses dates
import React, { Component, Fragment } from 'react'
import { RadialChart } from 'react-vis'
import { DATE_FORMAT, MAX_END, MAX_START } from '../../common/globalVariables.js'
// Import Style
import './StatsCounts.scss'


/**
 * Loops through Lift Data 1x
 */
class StatsCounts extends Component {
  render () {
    const { liftData, runData, values } = this.props

    let count = {
      run: 0,
      lift: 0
    }
    let rangeCount = {
      run: 0,
      lift: 0
    }

    const rangeStart = moment(values[0], 'X')
    const rangeEnd = moment(values[1], 'X')
    const globalWeekLength = MAX_END.diff(MAX_START, 'weeks')
    const rangeWeekLength = rangeEnd.diff(rangeStart, 'weeks')
    const globalDayLength = MAX_END.diff(MAX_START, 'day')
    const rangeDayLength = rangeEnd.diff(rangeStart, 'day')
    let totalMiles = 0
    let totalRangeMiles = 0
    const calendarData = []
    const longestStreak = {
      streak: null,
      date: null,
      rangeStreak: null,
      rangeDate: null
    }

    function runCount (runData, values) {
      let prevDate = 0
      for (let i = 0; i < runData.length; i++ ) {
        const DAY_DATE = moment(runData[i].date, 'MMMM D, YYYY a:m A').format('X')
        totalMiles += runData[i].meters
        if (DAY_DATE !== prevDate) {
          count.run++
        }
        if (DAY_DATE >= values[0] && DAY_DATE <= values[1]) {
          totalRangeMiles += runData[i].meters
          rangeCount.run++
        }
        prevDate = DAY_DATE
      }
    }
    runCount(runData, values)

    function addMaxes (liftData, values) {
      let prevDate = 0
      for (let i = 0; i < liftData.length; i++ ) {
        const itemDate = parseInt(moment(`${liftData[i]['Date']}`, DATE_FORMAT).format('X'))
        if (itemDate !== prevDate) {
          count.lift++
        }
        if (itemDate >= values[0] && itemDate <= values[1]) {
          if (itemDate !== prevDate) {
            rangeCount.lift++
          }
        }
        prevDate = itemDate
      }
    }
    addMaxes(liftData, values)

    /**
     * Combine Runs and Gyms to calculate longest streak
     * @param {object} runData
     */
    function pushRunDataToCalendar(runData) {
      for (let i = 0; i < runData.length; i++ ) {
        const DAY_DATE = moment(runData[i].date, 'MMMM D, YYYY a:m A').format(DATE_FORMAT)
        calendarData.push(`${DAY_DATE}`)
      }
    }
    pushRunDataToCalendar(runData)

    /**
     * Combine Runs and Gyms to calculate longest streak
     * @param {object} liftData
     */
    function pushLiftDataToCalendar(liftData) {
      for (let i = 0; i < liftData.length; i++ ) {
        calendarData.push(liftData[i].Date)
      }
    }
    pushLiftDataToCalendar(liftData)

    /**
     * Loop through calendar items and find longest streak
     * @param {object} calendarData
     */
    function setLongestStreak (calendarData, values) {
      const items = calendarData.sort()
      const tempLongestStreak = {
        streak: 0,
        date: null,
        rangeStreak: 0,
        rangeDate: null
      }

      for (let i = 0; i < items.length; i++ ) {
        if (items[i] === tempLongestStreak.date) {
          // it's still the same day. so ignore
        } else if (moment(items[i], DATE_FORMAT).subtract(1, 'days').format(DATE_FORMAT) === tempLongestStreak.date) {
          // it's the next day
          tempLongestStreak.streak++
          tempLongestStreak.date = items[i]

          if (moment(items[i], DATE_FORMAT).format('X') >= values[0] && moment(items[i], DATE_FORMAT).format('X') <= values[1]) {
            // it's the next day and it's in range
            tempLongestStreak.rangeStreak++
            tempLongestStreak.rangeDate = items[i]
          }
        } else {
          // it's neither the same day or the next, streak broken
          tempLongestStreak.streak = 1
          tempLongestStreak.rangeStreak = 1
        }
        // though every loop, if the streak is longer than the current best, update current best
        if (tempLongestStreak.streak > longestStreak.streak) {
          longestStreak.streak = tempLongestStreak.streak
          longestStreak.date = tempLongestStreak.date
        }
        if ((moment(items[i], DATE_FORMAT).format('X') >= values[0] && moment(items[i], DATE_FORMAT).format('X') <= values[1]) && (tempLongestStreak.rangeStreak > longestStreak.rangeStreak)) {
          longestStreak.rangeStreak = tempLongestStreak.rangeStreak
          longestStreak.rangeDate = tempLongestStreak.rangeDate
        }
        // set the temp date to today for next iteration
        tempLongestStreak.date = items[i]
      }
    }
    setLongestStreak(calendarData, values)

    function CountItems (props) {
      const dualItems =
        <div
          className='maxes__single'
        >
          <RadialChart
            className='maxes__ratio'
            data={[
              { angle: rangeCount.lift,
                innerRadius: 0.7,
                radius: 1,
                label: 'Gym',
                subLabel: `${Math.ceil(rangeCount.lift / (rangeCount.run + rangeCount.lift) * 100)}%`
              },
              { angle: rangeCount.run,
                innerRadius: 0.7,
                radius: 1,
                label: 'Run',
                subLabel: `${Math.ceil(rangeCount.run / (rangeCount.run + rangeCount.lift) * 100)}%`
              }
            ]}
            width={150}
            height={110}
            labelsRadiusMultiplier={1.4}
            labelsStyle={{
              fontSize: 12
            }}
            showLabels
          />
          <div className='single__lifeBest'>
            <span>Lifetime</span>
            <span>Run:{Math.ceil(count.run / (count.run + count.lift) * 100)}% Gym: {Math.floor(count.lift / (count.run + count.lift) * 100)}%</span>
            <span></span>
          </div>
        </div>

      const weeklyMiles =
        <div className='maxes__single'>
          <RadialChart
            className='maxes__radial maxes__radial--avgMiles'
            data={[
              { angle: parseFloat(`${Math.floor((totalRangeMiles * 0.000621371) / rangeWeekLength) - Math.floor((totalMiles * 0.000621371) / globalWeekLength)}`),
                innerRadius: 0.8,
                radius: 1
              },
              { angle: parseFloat(`${Math.floor((totalMiles * 0.000621371) / globalWeekLength)}`),
                innerRadius: 0.8,
                radius: 1
              }
            ]}
            width={110}
            height={110}
          />
          <div className='single__currentBest'>
            <span className='max__number'>{Math.floor((totalRangeMiles * 0.000621371) / rangeWeekLength)}</span>
            <span>Avg. Miles</span>
            <span className='small'>per week</span>
          </div>
          <div className='single__lifeBest'>
            <span>{Math.floor((totalMiles * 0.000621371) / globalWeekLength)} miles</span>
            <span>Total average</span>
          </div>
        </div>

      const longestStreakChart =
        <div className='maxes__single'>
          <RadialChart
            className='maxes__radial maxes__radial--streak'
            data={[
              { angle: parseInt(`${longestStreak.streak - longestStreak.rangeStreak}`),
                innerRadius: 0.8,
                radius: 1
              },
              { angle: parseInt(`${longestStreak.rangeStreak}`),
                innerRadius: 0.8,
                radius: 1
              }
            ]}
            width={110}
            height={110}
          />
          <div className='single__currentBest'>
            <span className='max__number'>{`${longestStreak.rangeStreak}`}</span>
            <span>Days</span>
            <span className='small'>{moment(longestStreak.rangeDate, DATE_FORMAT).format('MM/DD/YY')}</span>
          </div>
          <div className='single__lifeBest'>
            <span>PR: {`${longestStreak.streak}`}</span>

            <span>{moment(longestStreak.date, DATE_FORMAT).format('MMM D, YYYY')}</span>
          </div>
        </div>

      const activityAverage =
        <div className='maxes__single'>
          <RadialChart
            className='maxes__radial maxes__radial--avgActivities'
            data={[
              { angle: 4.1 - 4.6,
                innerRadius: 0.8,
                radius: 1
              },
              { angle: 4.6,
                innerRadius: 0.8,
                radius: 1
              }
            ]}
            width={110}
            height={110}
          />
          <div className='single__currentBest'>
            <span className='max__number'>{(((rangeCount.run + rangeCount.lift) / rangeDayLength) * (rangeDayLength / rangeWeekLength)).toFixed(1)}</span>
            <span>Activities</span>
            <span className='small'>/week</span>
          </div>
          <div className='single__lifeBest'>
            <span>{(((count.run + count.lift) / globalDayLength) * (globalDayLength / globalWeekLength)).toFixed(1)}</span>
            <span>Total average</span>
          </div>
        </div>

      return (
        <Fragment>
          {dualItems}
          {weeklyMiles}
          {longestStreakChart}
          {activityAverage}
        </Fragment>
      )
    }
    return (
      <CountItems />
    )
  }
}

export default StatsCounts
