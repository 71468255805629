import React, { Component } from 'react'

// Import Style
import './LoadScreen.scss'

class LoadScreen extends Component {
  render () {
    return (
      <div className='loader-wrap'>
        <div className='loader__text'>
          Do you even run bruh?
        </div>
      </div>
    )
  }
}

export default LoadScreen
