import React from 'react'
import { getTrackBackground, Range } from 'react-range'
// import moment from 'moment' // parses dates
import { MAX_END, MAX_START } from '../../common/globalVariables.js'
// Import Style
import './RangeSlider.scss'


class RangeSlider extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      values: [
        MAX_START,
        MAX_END
      ]
    }
  }

  render () {
    const { values } = this.props

    const sliderMin = parseInt(MAX_START.format('X'))
    const sliderMax = parseInt(MAX_END.format('X'))

    return (
      <div className='wrap--slider' >

        <Range
          values={values}
          step={86400} // One day
          min={sliderMin}
          max={sliderMax}
          allowOverlap={false}
          onChange={values => {
            this.props.onValuesChange(values)
          }}
          renderTrack={({ props, children }) => (
            <div
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              className='slider__inner'
              style={{
                ...props.style
              }}
            >
              <div
                ref={props.ref}
                className='slider__track'
                style={{
                  background: getTrackBackground({
                    values: values,
                    colors: ['#ccc', '#5dcbce', '#ccc'],
                    min: sliderMin,
                    max: sliderMax
                  }),
                  alignSelf: 'center'
                }}
              >
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props, isDragged }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: '42px',
                width: '42px',
                borderRadius: '4px',
                backgroundColor: '#FFF',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow: '0px 2px 6px #AAA'
              }}
            >
              <div
                className='slider__handle__inner'
                style={{
                  backgroundColor: isDragged ? '#5dcbce' : '#CCC'
                }}
              />
            </div>
          )}
        />
      </div>
    )
  }
}

export default RangeSlider
