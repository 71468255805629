import React, { Component } from 'react'
import StatsCounts from '../StatsCounts/StatsCounts.js'
import StatsMaxLifts from '../StatsMaxLifts/StatsMaxLifts.js'
// Import Style
import './StatsBar.scss'

class StatsBar extends Component {
  render () {
    const { liftData, runData, values } = this.props
    return (
      <div className="chartWrap chartWrap--totals">
        <div className='maxes'>
          <StatsCounts
            values={values}
            liftData={liftData}
            runData={runData}
          />
          <StatsMaxLifts
            values={values}
            liftData={liftData}
          />
        </div>
      </div>
    )
  }
}

export default StatsBar
