import Moment from 'moment' // parses dates
import { extendMoment } from 'moment-range'
import React, { Component } from 'react'
import CalendarHeatmap from 'react-calendar-heatmap'
import { DATE_FORMAT } from '../../common/globalVariables.js'
// Import Style
import './CalendarChart.scss'


const moment = extendMoment(Moment)

class CalendarChart extends Component {
  render () {
    const { runData, liftData, values } = this.props // pass import in as props

    const calendarData = []

    function pushRunDataToCalendar(runData) {
      for (let i = 0; i < runData.length; i++ ) {
        const DAY_DATE = moment(runData[i].date, 'MMMM D, YYYY a:m A').format(DATE_FORMAT)
        calendarData.push({ date: `${DAY_DATE}`, count: 'run' })
      }
    }
    pushRunDataToCalendar(runData)

    function pushLiftDataToCalendar(liftData) {
      for (let i = 0; i < liftData.length; i++ ) {  
        calendarData.push({ date: liftData[i].Date, count: 'gym' })
      }
    }
    pushLiftDataToCalendar(liftData)

    let xMin = moment(values[0], 'X')
    let xMax = moment(values[1], 'X')

    return (
      <div className="chartWrap chartWrap--calendar">
        <CalendarHeatmap
          values={calendarData}
          startDate={xMin}
          endDate={xMax}
          showOutOfRangeDays
          classForValue={(value) => {
            if (!value) {
              return 'color-empty'
            }
            return `color-scale-${value.count}`
          }}
        />
      </div>
    )
  }
}

export default CalendarChart
