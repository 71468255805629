import moment from 'moment' // parses dates

const LIFT_NAMES = {
  'Deadlift': 'deadlift',
  'Barbell Squat': 'squat',
  'Flat Barbell Bench Press': 'bench',
  'Barbell Row': 'row',
  'Overhead Press': 'press'
}
const DATE_FORMAT = 'YYYY-MM-DD'
const MAX_START = moment('2016-01-01', DATE_FORMAT)
const MAX_END = moment('2020-03-14', DATE_FORMAT)

export {
  DATE_FORMAT,
  LIFT_NAMES,
  MAX_START,
  MAX_END
}
