import moment from 'moment' // parses dates
import React, { Component } from 'react'
import { DiscreteColorLegend, FlexibleWidthXYPlot, HorizontalGridLines, LineSeries, VerticalRectSeries, XAxis, YAxis } from 'react-vis'
import { DATE_FORMAT } from '../../common/globalVariables.js'
// Import Style
import './Chart-run-distance.scss'

/**
 * Renders a bar graph of individual runs and weekly totals across time
 * Loops through running Data 3x
 */
class RunRender extends Component {

  render () {
    const { runData, values } = this.props

    const DAY_STAGING = {}
    const PACE_STAGING = {}
    const WEEK_STAGING = {}
    const DAY_DATA = []
    const WEEK_DATA = []
    const PACE_DATA = []

    /**
     * Add each run date and sum of runs to staging data
     * @param {Object} item json object containing date,distance,time
     */
    function stageSingleRuns (item) {
      const DAY_DATE = moment(item.date, 'MMMM D, YYYY a:m A').format(DATE_FORMAT)
      const DAY_TIMESTAMP = moment(DAY_DATE, DATE_FORMAT).format('X')
      const DAY_DISTANCE = parseFloat((item.meters * 0.000621371).toFixed(2))
      const DAY_PACE = parseFloat(item.seconds / DAY_DISTANCE).toFixed(2)

      if (DAY_TIMESTAMP >= values[0] && DAY_TIMESTAMP <= values[1]) {
        DAY_STAGING[DAY_DATE] = (+DAY_STAGING[DAY_DATE] || 0) + DAY_DISTANCE
        PACE_STAGING[DAY_DATE] = DAY_PACE
      }
    }

    /**
     * Add week:distance total pairs to staging data
     * @param {Object} item json object containing date,distance,time
     */
    function stageWeeklyRuns (item) {
      const WEEK_DATE = moment(item.date, 'MMMM D, YYYY a:m A').startOf('week').format(DATE_FORMAT)
      const WEEK_TIMESTAMP = moment(WEEK_DATE, DATE_FORMAT).format('X')
      const WEEK_DISTANCE = parseFloat((item.meters * 0.000621371).toFixed(2))

      if (WEEK_TIMESTAMP >= values[0] && WEEK_TIMESTAMP <= values[1]) {
        WEEK_STAGING[WEEK_DATE] = (+WEEK_STAGING[WEEK_DATE] || 0) + WEEK_DISTANCE
      }
    }

    /**
     * Loop 1 - Loop through raw data and transform to keyed format
     */
    function stageData () {
      for (let i = 0; i < runData.length; i++ ) {
        stageSingleRuns(runData[i])
        stageWeeklyRuns(runData[i])
      }
    }

    /**
     * Loop 2 - loop through staging data and format day data for bar graph
     */
    function setDayData () {
      // loop through the daily staging data and create properly formated objects in data
      for (const [key, value] of Object.entries(DAY_STAGING)) {
        const DAY_START = moment(`${key}`, DATE_FORMAT)
        const DAY_END = moment(`${key}`, DATE_FORMAT).add(1, 'days')

        DAY_DATA.push({ x0: DAY_START, x: DAY_END, y: parseFloat(`${value}`) })
      }
    }

    /**
     * Loop 3 - loop through staging data and format week data for bar graph
     */
    function setWeekData () {
      for (let [key, value] of Object.entries(WEEK_STAGING)) {
        if (!value) {
          value = 0
        }
        const WEEK_START = moment(`${key}`, DATE_FORMAT)
        const WEEK_END = moment(`${key}`, DATE_FORMAT).add(1, 'weeks')
        WEEK_DATA.push({ x0: WEEK_START, x: WEEK_END, y: parseFloat(`${value}`) })
      }
    }

    /**
     * Loop 4 - loop through staging data and format pace data for bar graph
     */
    function setPaceData () {
      // loop through the pace staging data and create properly formated objects in data
      for (const [key, value] of Object.entries(PACE_STAGING)) {
        const PACE_DATE = moment(`${key}`, DATE_FORMAT)

        PACE_DATA.push({ x: PACE_DATE, y: parseFloat(`${value}` / 60) })
      }
    }

    /**
     * run functions to format staged data and push to day and week list
     */
    function pushFormatedData () {
      setDayData()
      setWeekData()
      setPaceData()
    }

    // sequence to stage data then format data for output
    function processData () {
      stageData()
      pushFormatedData()
    }
    processData()

    /**
     * Returns the browser window height / 3
     */
    function getAppHeight () {
      const divHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
      return Math.max((divHeight / 3) - 50, 250)
    }

    let xMin = moment(values[0], 'X')
    let xMax = moment(values[1], 'X')
    return (
      <FlexibleWidthXYPlot
        xType='time'
        height={getAppHeight()}
        margin={
          { bottom: 90,
            right: 40
          }
        }
        xDomain={[xMin, xMax]}
        animation={false}
        className='chartWrap chartWrap--run'
      >
        <HorizontalGridLines tickTotal={4} />
        <XAxis
          tickFormat={function tickFormat (d) {
            const date = moment(d).format('MMM D, YY')
            return date
          }}
          tickLabelAngle={-66}
          stroke='#dddddd'
        />
        <YAxis
          // title='Miles'
          tickPadding={4}
        />
        <YAxis
          title='Pace'
          orientation='right'
          position='end'
          // left={0}
          tickPadding={4}
          tickFormat={function tickFormat (d) {
            let minutes = Math.floor(d)
            return `${minutes}:00`
          }}
        />
        <VerticalRectSeries
          className='fill--runWeek'
          data={WEEK_DATA}
        />
        <VerticalRectSeries
          className='fill--run'
          data={DAY_DATA}
        />
        <LineSeries
          className='line'
          data={PACE_DATA}
          strokeWidth={1}
        />
        <DiscreteColorLegend
          orientation='horizontal'
          colors={[
            '#f77134',
            'rgba(247, 178, 52, 0.7)',
            'rgb(26, 49, 119)'
          ]}
          items={[
            'Miles/day',
            'Miles/week',
            'pace'
          ]}
        />
      </FlexibleWidthXYPlot>
    )
  }
}

export default RunRender
