import * as Papa from 'papaparse' // parses csv to json
import { Component } from 'react'
import dataSrc from "../data/run.local.csv"

class RunLoader extends Component {
  _isMounted = false

  constructor (props) {
    super(props)
    this.handleDataLoaded = this.handleDataLoaded.bind(this)
  }

  /**
   * runs onRunDataLoaded in the parent component
   * @param {Object} data Loaded running json
   */
  handleDataLoaded (data) {
    this.props.onRunDataLoaded(data) // Bound to render component
  }

  /**
   * Load csv file from google drive and parse to json
   * handleDataLoaded() Callback
   */
  componentDidMount() {
    this._isMounted = true

    // const REMOTE_SRC ='https://doc-0c-50-sheets.googleusercontent.com/pub/l5l039s6ni5uumqbsj9o11lmdc/n78a94tmpm6a49jt35kckcehtg/1599874020000/105388776304960720697/*/e@2PACX-1vSP6b-QhUS-qNuPhwR2UcVOTKQ4lmOHJO9YGmanIqkWYNtCKLN1h2b_1oZuCe-Qs4z6uPViiwPZ_YQo?gid=0&single=true&output=csv'
    const REMOTE_SRC = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vSP6b-QhUS-qNuPhwR2UcVOTKQ4lmOHJO9YGmanIqkWYNtCKLN1h2b_1oZuCe-Qs4z6uPViiwPZ_YQo/pub?gid=0&single=true&output=csv'
    const LOCAL_SRC = dataSrc

    const DEV = true
    const SRC = (DEV === true) ? LOCAL_SRC : REMOTE_SRC

    Papa.parse(`${SRC}`, {
      download: true,
      header: true,
      dynamicTyping: true,
      complete: (results) => {
        if (this._isMounted) {
          this.handleDataLoaded(results.data)
        }
      }
    })
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render () {
    return (null)
  }
}

export default RunLoader
