import moment from 'moment' // parses dates
import React, { Component, Fragment } from 'react'
import './app.scss'
import CalendarChart from './components/CalendarChart/CalendarChart.js'
import LiftRender from './components/Chart-lift-orm/Chart-lift-orm.js'
import RunRender from './components/Chart-run-distance/Chart-run-distance.js'
import LoadScreen from './components/LoadScreen/LoadScreen.js'
import RangeSlider from './components/RangeSlider/RangeSlider.js'
import StatsBar from './components/StatsBar/StatsBar.js'
import LiftLoader from './dataLoaders/liftLoader.js'
import RunLoader from './dataLoaders/runLoader.js'
import { MAX_END } from './common/globalVariables.js'

/**
 * Imports the for lifts and runs
 * Once the data is loaded it renders components
 */
class App extends Component {
  constructor (props) {
    super(props)
    this.handleValuesChange = this.handleValuesChange.bind(this) // Slider value changes
    this.onRunDataLoaded = this.onRunDataLoaded.bind(this) // Run data loaded
    this.onLiftDataLoaded = this.onLiftDataLoaded.bind(this) // Lift data loaded
    this.state = {
      dataLoaded: false,
      values: [
        parseInt(moment(MAX_END).subtract(2, 'years').format('X')),
        parseInt(MAX_END.format('X'))
      ]
    }
    this.liftLoaded = false
    this.runLoaded = false
    this.runData = []
    this.liftData = []
  }

  /**
   * Called from in the Slider component
   * @param {array} newValues [min, max] from slider
   */
  handleValuesChange (newValues) {
    this.setState({ values: newValues })
  }

  /**
   * Called from in the RunLoader component
   * @param {object} data Json object containing all runs
   */
  onRunDataLoaded (data) {
    this.runLoaded = true
    this.runData = data
    if (this.liftLoaded === true) {
      this.setState({ dataLoaded: true })
    }
  }

  /**
   * Called from in the LiftLoader component
   * @param {object} data Json object containing all lift sets
   */
  onLiftDataLoaded (data) {
    this.liftLoaded = true
    this.liftData = data
    if (this.runLoaded === true) {
      this.setState({ dataLoaded: true })
    }
  }

  render () {
    return (
      <Fragment>
        {
          (!this.state.dataLoaded) ? (
            <Fragment>
              <LoadScreen />
              <RunLoader onRunDataLoaded={this.onRunDataLoaded} />
              <LiftLoader onLiftDataLoaded={this.onLiftDataLoaded} />
            </Fragment>
          ) : (
            <Fragment>
              <StatsBar
                values={this.state.values}
                liftData={this.liftData}
                runData={this.runData}
              />

              <RangeSlider
                values={this.state.values}
                onValueChange={this.handleValueChange}
                onValuesChange={this.handleValuesChange}
              />

              <RunRender
                runData={this.runData}
                values={this.state.values}
              />

              <LiftRender
                liftData={this.liftData}
                values={this.state.values}
              />

              <CalendarChart
                runData={this.runData}
                liftData={this.liftData}
                values={this.state.values}
              />
            </Fragment>
          )
        }
      </Fragment>
    )
  }
}
export default App
