import * as Papa from 'papaparse' // parses csv to json
import { Component } from 'react'
import dataSrc from "../data/FitNotes_Export.csv"

class LiftLoader extends Component {
  constructor (props) {
    super(props)
    this.handleDataLoaded = this.handleDataLoaded.bind(this)
    this.importLiftData()
  }

  /**
   * runs onLiftDataLoaded in the parent component
   * @param {Object} data Loaded lift json
   */
  handleDataLoaded (data) {
    this.props.onLiftDataLoaded(data) // Bound to render component
  }

  /**
   * Load local csv file and parse to json
   * handleDataLoaded() Callback
   */
  importLiftData () {
    Papa.parse(dataSrc, {
      download: true,
      header: true,
      dynamicTyping: true,
      complete: (results) => {
        this.handleDataLoaded(results.data)
      }
    })
  }

  render () {
    return (null)
  }
}

export default LiftLoader
