import React, { Component } from 'react'
import { RadialChart } from 'react-vis'
import moment from 'moment' // parses dates
import { DATE_FORMAT, LIFT_NAMES} from '../../common/globalVariables.js'

// Import Style
import './StatsMaxLifts.scss'

class StatsMaxLifts extends Component {
  render () {
    const { liftData, values } = this.props
    const liftMaxes = {}
    const liftDateMaxes = {}
    const liftRangeMaxes = {}
    const maxes = []

    function addMaxes (liftData, values) {
      for (let i = 0; i < liftData.length; i++ ) {
        const itemDate = parseInt(moment(`${liftData[i]['Date']}`, DATE_FORMAT).format('X'))
        const itemLift = liftData[i]['Exercise']

        if (!(liftMaxes[itemLift] > liftData[i]['Weight (lbs)'])) {
          liftMaxes[itemLift] = liftData[i]['Weight (lbs)']
          liftDateMaxes[itemLift] = liftData[i]['Date']
        }

        if (itemDate >= values[0] && itemDate <= values[1]) {
          liftRangeMaxes[itemLift] = liftRangeMaxes[itemLift] >= liftData[i]['Weight (lbs)'] ? liftRangeMaxes[itemLift] : liftData[i]['Weight (lbs)']
        }
      }
    }

    function mergeMaxes () {
      for (const [key, value] of Object.entries(LIFT_NAMES)) {
        maxes.push({
          name: `${value}`,
          best: parseInt(`${liftMaxes[key]}`),
          bestDate: `${liftDateMaxes[key]}`,
          rangeBest: parseInt(`${liftRangeMaxes[key]}`)
        })
      }
    }

    addMaxes(liftData, values)
    mergeMaxes()

    function ListOfMaxes (props) {
      const singleMaxes = maxes.map((item, i) =>
        <div
          key={i}
          className='maxes__single'
        >
          <RadialChart
            className={`maxes__radial maxes__radial--${item.name}`}
            data={[
              { angle: item.best - item.rangeBest,
                innerRadius: 0.8,
                radius: 1
              },
              { angle: item.rangeBest,
                innerRadius: 0.8,
                radius: 1
              }
            ]}
            width={110}
            height={110}
          />

          <div className='single__currentBest'>
            <span className='max__number'>{item.rangeBest}</span>
            <span>{item.name}</span>
            <span className='small'>Max lbs</span>
          </div>
          <div className='single__lifeBest'>
            <span>PR: {item.best}</span>
            <span>{moment(item.bestDate, DATE_FORMAT).format('MMM D, YYYY')}</span>
          </div>
        </div>
      )
      return (
        <div className='maxes'>
          {singleMaxes}
        </div>
      )
    }
    return (
      <ListOfMaxes />
    )
  }
}

export default StatsMaxLifts
