import moment from 'moment' // parses dates
import React, { Component } from 'react'
import { DiscreteColorLegend, FlexibleWidthXYPlot, HorizontalGridLines, LineMarkSeries, XAxis, YAxis } from 'react-vis'
import { DATE_FORMAT, LIFT_NAMES } from '../../common/globalVariables.js'
// Import Style
import './Chart-lift-orm.scss'


/**
 * Renders a lift graph
 * Loops through LiftData 1x
 */
class LiftRender extends Component {
  constructor (props) {
    super(props)
    this.state = {
      mute_deadlift: false,
      mute_squat: false,
      mute_bench: false,
      mute_row: false,
      mute_press: false
    }
  }

  /**
   * set the muted states
   */
  setMuteStates (stateProp) {
    switch (stateProp) {
      case 'deadlift':
        if (this.state.mute_deadlift) {
          this.setState({ mute_deadlift: false })
        } else {
          this.setState({ mute_deadlift: true })
        }
        break
      case 'squat':
        if (this.state.mute_squat) {
          this.setState({ mute_squat: false })
        } else {
          this.setState({ mute_squat: true })
        }
        break
      case 'bench':
        if (this.state.mute_bench) {
          this.setState({ mute_bench: false })
        } else {
          this.setState({ mute_bench: true })
        }
        break
      case 'row':
        if (this.state.mute_row) {
          this.setState({ mute_row: false })
        } else {
          this.setState({ mute_row: true })
        }
        break
      case 'press':
        if (this.state.mute_press) {
          this.setState({ mute_press: false })
        } else {
          this.setState({ mute_press: true })
        }
        break
      default:
    }
  }

  render () {
    const { liftData, values } = this.props
    const LIFTS = {
      DEADLIFT_DATA: [],
      SQUAT_DATA: [],
      BENCH_DATA: [],
      ROW_DATA: [],
      PRESS_DATA: []
    }

    /**
     * Calculate One Rep Max from reps and weight
     * @param {number} reps rep count
     * @param {string} weight lift name
     */
    function calcOrm (reps, weight) {
      const ORM = weight / ((1.0278) - (0.0278 * Math.min(reps, 10))) // reps > 10 aren't accurate
      return Math.round(ORM)
    }

    /**
     * Push data to appropriate list
     * @param {string} lift lift name
     * @param {object} liftDate Date
     * @param {number} orm one rep max
     */
    function updateLiftList (lift, liftDate, orm) {
      switch (LIFT_NAMES[lift]) {
        case 'deadlift':
          LIFTS.DEADLIFT_DATA.push({ x: liftDate, y: orm })
          break
        case 'squat':
          LIFTS.SQUAT_DATA.push({ x: liftDate, y: orm })
          break
        case 'bench':
          LIFTS.BENCH_DATA.push({ x: liftDate, y: orm })
          break
        case 'row':
          LIFTS.ROW_DATA.push({ x: liftDate, y: orm })
          break
        case 'press':
          LIFTS.PRESS_DATA.push({ x: liftDate, y: orm })
          break
        default:
      }
    }

    /**
     * set Previous Lift's values
     * @param {string} lift lift name
     * @param {number} date date
     * @param {number} orm one rep max
     */
    function setPrevious (lift, date, orm) {
      prevDate = date
      prevItem[`${lift}`] = orm
    }

    /**
     * Compare to previous entry and while it's the same date and lift, update maxes
     * When new (not same), update master list with best max
     * @param {Object} liftData
     */
    function compareToLast (liftData) {
      setPrevious(
        liftData[0]['Exercise'],
        parseInt(moment(`${liftData[0]['Date']}`,DATE_FORMAT).format('X')),
        calcOrm(liftData[0]['Reps'], liftData[0]['Weight (lbs)'])
      )
      let i = 0
      for (i = 0; i <= liftData.length; i++) {
        if (i < liftData.length) {
          const itemDate = parseInt(moment(`${liftData[i]['Date']}`, DATE_FORMAT).format('X'))
          const itemLift = liftData[i]['Exercise']
          const itemORM = calcOrm(liftData[i]['Reps'], liftData[i]['Weight (lbs)'])

          if (itemDate === prevDate) {
            if (!(itemLift in prevItem) || (itemORM > prevItem[`${itemLift}`])) {
              prevItem[`${itemLift}`] = itemORM
            }
          } else {
            if (prevDate >= values[0] && prevDate <= values[1]) {
              for (let [key, value] of Object.entries(prevItem)) {
                updateLiftList(
                  `${key}`,
                  moment(prevDate, 'X'),
                  value
                )
              }
            }
            prevItem = {}
            setPrevious(itemLift, itemDate, itemORM)
          }
        }
      }
    }

    // temporary values to compare prev to current
    let prevItem = {}
    let prevDate
    compareToLast(liftData)

    /**
     * Returns the browser window height / 3
     */
    function getAppHeight () {
      const divHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
      return Math.max((divHeight / 3) - 50, 250)
    }

    let xMin = moment(values[0], 'X')
    let xMax = moment(values[1], 'X')
    return (
      <FlexibleWidthXYPlot
        height={getAppHeight()}
        margin={
          { bottom: 90,
            right: 40
          }
        }
        xType='time'
        xDomain={[xMin, xMax]}
        animation={false}
        className='chartWrap chartWrap--orm'
      >
        <DiscreteColorLegend
          orientation='horizontal'
          colors={[
            '#ee53e9',
            '#b353ee',
            '#7253ee',
            '#5374ee',
            '#53b5ee'
          ]}
          items={[
            'deadlift',
            'squat',
            'bench',
            'row',
            'press'
          ]}
          onItemClick={(item, index, event) => {
            this.setMuteStates(item)
          }}
        />
        <HorizontalGridLines />
        <XAxis
          tickFormat={function tickFormat (d) {
            const date = moment(d).format('MMM D, YY')
            return date
          }}
          tickLabelAngle={-66}
          stroke='#dddddd'
        />
        <YAxis />
        <LineMarkSeries
          className='line line--deadlift'
          size={2}
          data={LIFTS.DEADLIFT_DATA}
          style={{ opacity: parseFloat(`${this.state.mute_deadlift ? 0.2 : 1}`) }}
        />
        <LineMarkSeries
          className='line line--squat'
          size={2}
          data={LIFTS.SQUAT_DATA}
          style={{ opacity: parseFloat(`${this.state.mute_squat ? 0.2 : 1}`) }}
        />
        <LineMarkSeries
          className='line line--bench'
          size={2}
          data={LIFTS.BENCH_DATA}
          style={{ opacity: parseFloat(`${this.state.mute_bench ? 0.2 : 1}`) }}
        />
        <LineMarkSeries
          className='line line--row'
          size={2}
          data={LIFTS.ROW_DATA}
          style={{ opacity: parseFloat(`${this.state.mute_row ? 0.2 : 1}`) }}
        />
        <LineMarkSeries
          className='line line--press'
          size={2}
          data={LIFTS.PRESS_DATA}
          style={{ opacity: parseFloat(`${this.state.mute_press ? 0.2 : 1}`) }}
        />

      </FlexibleWidthXYPlot>
    )
  }
}

export default LiftRender
